import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-404',
  templateUrl: './404.component.html'
})
/* tslint:disable-next-line:class-name */
export class _404Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
