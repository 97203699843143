import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

type Constructor<T = {}> = new (...args: any[]) => T;

export const DestroyedSubjectContainerMixin = <T extends Constructor>(base: T = class { } as T) =>
  class extends base implements OnDestroy {
    destroyed$ = new Subject<void>();

    ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
    }
  };
