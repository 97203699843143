import { ActivatedRoute } from '@angular/router';

function slugToTitle(slug: string) {
  return slug.replace('-', ' ').replace(
    /\w\S*/g,
    function (word) {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    }
  )
}

export function getRouteTitlesNested(activatedRoute: ActivatedRoute) {
  const titles = [];

  let route = activatedRoute;
  while (route) {
    if (route.snapshot && route.snapshot.data && route.snapshot.data.title) {
      titles.push(route.snapshot.data.title);
    }
    if (route.outlet !== 'primary') {
      break;
    }
    route = route.firstChild;
  }

  return titles;
}

export function getRouteTitleNested(activatedRoute: ActivatedRoute) {
  const titles = this.getRouteTitlesNested(activatedRoute);
  if (titles && titles.length) {
    return titles.reverse().join(' - ');
  }
  return null;
}

export function getRouteTitle(activatedRoute: ActivatedRoute, slugIfNotTitle: boolean = false) {
  let route = activatedRoute;
  while (route.firstChild) {
    if (route.outlet !== 'primary') {
      break;
    }
    route = route.firstChild;
  }

  if (route.snapshot && route.snapshot.data && route.snapshot.data.title) {
    return route.snapshot.data.title;
  }

  if (slugIfNotTitle && route.snapshot && route.snapshot.url.length) {
    return slugToTitle(route.snapshot.url[route.snapshot.url.length - 1].path);
  }

  return null;
}
