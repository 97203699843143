import { enableProdMode, ApplicationRef } from '@angular/core';
import { environment } from './environments/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableDebugTools } from '@angular/platform-browser';
import 'zone.js';

import { AppBrowserModule } from 'app/app.browser.module';

if (environment.production) {
  enableProdMode();
}

document.addEventListener("DOMContentLoaded", () => {
  platformBrowserDynamic().bootstrapModule(AppBrowserModule)
    .then(module => {
      if (!environment.production) {
        const applicationRef = module.injector.get(ApplicationRef);
        const appComponent = applicationRef.components[0];
        enableDebugTools(appComponent);
      }
    })
    .catch(err => console.log(err));
});
