import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Cacheable } from 'ngx-cacheable';
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from 'ngx-stripe';

import { environment } from '../../../environments/environment';

import { PurchaseData, ApiResponse, PurchasedItem, PurchasedItems } from '../../../shared';

@Injectable()
export class PaymentService {
  private readonly ROOT = `${environment.functionsURL}/app/payments`;

  constructor(private http: HttpClient, private stripe: StripeService) {
  }

  createStripeSourceFromCard(card: any): Observable<any> {
    return from<any>(this.stripe.createSource(card));
  }

  createPurchase(data: PurchaseData): Observable<ApiResponse<PurchasedItem, any>> {
    return this.http.post<ApiResponse<PurchasedItem, any>>(
      `${this.ROOT}/purchase`,
      data);
  }

  @Cacheable()
  getPurchases(): Observable<ApiResponse<PurchasedItems, any>> {
    return this.http.get<ApiResponse<PurchasedItems, any>>(
      `${this.ROOT}/purchased`);
  }

  @Cacheable()
  isPurchasedItemActive(itemId?: string): Observable<boolean> {
    return this.http.get<ApiResponse<boolean, any>>(
      `${this.ROOT}/purchased/active/${itemId ? (itemId) : ''}`)
      .pipe(map(res => !res.error && res.data));
  }

  cancelPurchased(itemId: string, feedback?: string): Observable<boolean> {
    return this.http.post<ApiResponse<boolean, any>>(
      `${this.ROOT}/purchased/cancel/${itemId}`, { feedback })
      .pipe(map(res => !res.error && res.data));
  }

}
