import { Injectable, Injector } from '@angular/core';
import { switchMap, first } from 'rxjs/operators';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import { AuthService } from '../services/auth.service';
import { Observable, from, of } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  auth: AuthService;

  constructor(private inj: Injector) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('oauthCallback') > -1) {
      return next.handle(request);
    }

    this.auth = this.inj.get(AuthService); // inject the authservice manually (see https://github.com/angular/angular/issues/18224)

    if (this.auth) {
      return from(this.handle(request, next));
    } else {
      return next.handle(request);
    }
  }

  private async handle(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const userToken$ = this.auth.getAuth().user.pipe(
      switchMap(user => {
        if (user) {
          return user.getIdToken();
        }
        return of(null);
      }));

    try {
      const userToken = await (userToken$.pipe(first()).toPromise());
      if (userToken) {
        return next.handle(request.clone({
          setHeaders: {
            Authorization: `Bearer ${userToken}`
          }
        })).toPromise();
      }
    } catch (err) {
      console.error(err);
    }

    return next.handle(request).toPromise();
  }

}
