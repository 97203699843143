import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable, of, from } from 'rxjs';
import { switchMap, map, first } from 'rxjs/operators';

import { User } from '../models/user';

import { environment } from 'environments/environment';
import { VERSION } from 'environments/version';

@Injectable()
export class AuthService {

  user: Observable<User>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router) {

    if (isPlatformBrowser(platformId)) {
      this.user = this.afAuth.authState.pipe(
        switchMap(user => {
          if (user) {
            return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
          } else {
            return of(null);
          }
        })
      );
    } else {
      this.user = of(null);
    }
  }

  getAuth() {
    return this.afAuth;
  }

  googleLogin(name: string, mailinglist: boolean) {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.oAuthLogin(provider, name, mailinglist);
  }

  private oAuthLogin(provider, name: string, mailinglist: boolean) {
    return this.afAuth.auth.signInWithPopup(provider)
      .then(credential => {
        this.updateUserData(credential.user, name, mailinglist);
      });
  }

  emailLogin(email: string, password: string) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);
  }

  emailSignup(name: string, email: string, password: string, mailinglist: boolean) {
    return this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then(credential => {
        this.updateUserData(credential.user, name, mailinglist);
      });
  }

  sendPasswordResetEmail(email: string) {
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }

  private updateUserData(user: firebase.User, displayName?: string, mailinglist?: boolean) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const data: User = {
      uid: user.uid,
      email: user.email || null,
      displayName: user.displayName || displayName || null,
      photoURL: user.photoURL || null,
      intro: true,
      mailinglist
    };
    return userRef.set(data, { merge: true });
  }

  signOut() {
    this.afAuth.auth.signOut().then(() => {
      localStorage.clear();

      window.location.assign('/');
    });
  }

  updatePassword(password: string) {
    if (this.afAuth.auth.currentUser) {
      return this.afAuth.auth.currentUser.updatePassword(password);
    }
  }

  // Used by the http interceptor to set the auth header
  getUserIdToken(): Observable<string> {
    return from(this.afAuth && this.afAuth.auth && this.afAuth.auth.currentUser && this.afAuth.auth.currentUser.getIdToken());
  }

  // Login popup window
  stripeLogin() {
    const popup = window.open(`${environment.functionsURL}/stripeRedirect`, '_blank', 'height=700,width=800');
  }
  // Signin with a custom token from
  customSignIn(token) {
    return this.afAuth.auth.signInWithCustomToken(token).then(() => window.close());
  }

  isAdmin(): Observable<boolean> {
    return this.afAuth && this.afAuth.idTokenResult && this.afAuth.idTokenResult.pipe(map(x => {
      return x && x.claims && x.claims.admin;
    }));
  }

  getUserPhotoUrl(user: User) {
    return user.photoURL || `https://ui-avatars.com/api/?size=128&name=${user.displayName}`;
  }

  updateUserIntro(uid, value) {
    return this.afs.doc(`users/${uid}`).set({ intro: value }, { merge: true });
  }

  updateUserLastUpdateTimestamp(uid, value) {
    return this.afs.doc(`users/${uid}`).set({ lastUpdateTimestamp: value }, { merge: true });
  }

  isUserLastUpdateTimestampBeforeBuildTimestamp(): Observable<boolean> {
    return this.user.pipe(
      first(),
      map(user => {
        if (!user) {
          return false;
        }
        if (!user.lastUpdateTimestamp) {
          return true;
        }
        const timestamp = (user.lastUpdateTimestamp as any).toDate();
        if (!timestamp) {
          return true;
        }
        return timestamp.getTime() < (new Date(VERSION.ts)).getTime();
      }));
  }

}
