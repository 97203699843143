import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { first, takeUntil } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material/snack-bar';

import { DestroyedSubjectContainerMixin } from '../../utils/angular-rxjs/destroyed-subject-container.mixin';

import { PaymentService } from '../../shared/services/payment.service';
import { AuthService } from '../../shared/services/auth.service';
import { ExService } from '../../shared/services/ex.service';

import { User } from '../../shared/models/user';

import { PurchaseData, ApiResponse, PurchasedItem, PurchasedItems, PurchaseItem } from '../../../shared';
import * as shared from '../../../shared';

@Component({
  selector: 'app-payment-item-actions',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class PaymentItemActionsComponent extends DestroyedSubjectContainerMixin() implements OnInit {

  @Input() purchaseItem: PurchaseItem;
  @Input() onCreatePurchase: Function;
  @Input() onCancelPurchase: Function;
  @Input() onStartPurchaseNoUser: Function;

  hasPurchasedAny: boolean;

  hasPurchased: boolean;
  canCancel: boolean;

  user: any;
  purchasedItems: PurchasedItems;

  loading = false;

  constructor(
    private router: Router,
    public auth: AuthService,
    public pmt: PaymentService,
    public snackBar: MatSnackBar,
    public exService: ExService
  ) {
    super();
  }

  ngOnInit() {
    this.auth.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
      if (user) {
        this.user = user;

        if (user) {
          this.pmt.isPurchasedItemActive().pipe(first()).subscribe(res => {
            this.hasPurchasedAny = res;
          });

          this.pmt.getPurchases().pipe(first()).subscribe(res => {
            if (res.error) {
              return;
            }

            this.purchasedItems = res.data;

            const purchasedItem = this.purchasedItems[this.purchaseItem.id];
            if (purchasedItem) {
              this.hasPurchased = purchasedItem.status === 'active';
              this.canCancel = this.hasPurchased && (
                shared.purchaseItems[this.purchaseItem.id]
                && shared.purchaseItems[this.purchaseItem.id].recurrenceType !== null);
            }
          });
        }
      }
    });
  }

  createPurchase() {
    this.loading = true;
    if (this.onCreatePurchase) {
      this.onCreatePurchase(this.purchaseItem);
    }
    this.loading = false;
  }

  cancelPurchase() {
    this.loading = true;
    if (this.onCancelPurchase) {
      this.onCancelPurchase(this.purchaseItem);
    }
    this.loading = false;
  }

  startPurchaseNoUser() {
    this.loading = true;
    if (this.onStartPurchaseNoUser) {
      this.onStartPurchaseNoUser(this.purchaseItem);
    }
    this.loading = false;
  }

}
