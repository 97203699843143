import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { first, takeUntil } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material/snack-bar';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatHorizontalStepper } from '@angular/material/stepper';

import { faListAlt, faUser, faCreditCard, faSmile, faCheckCircle, faEdit } from '@fortawesome/free-regular-svg-icons';

import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

import { DestroyedSubjectContainerMixin } from '../../utils/angular-rxjs/destroyed-subject-container.mixin';

import { User } from '../../shared/models/user';
import { AuthService } from '../../shared/services/auth.service';
import { PaymentService } from '../../shared/services/payment.service';

import { PurchaseData, ApiResponse, PurchasedItem, PurchasedItems, PurchaseItem } from '../../../shared';
import * as shared from '../../../shared';

import { PaymentFormComponent } from '../payment-form/component';

@Component({
  selector: 'app-payment-upgrade',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class PaymentUpgradeComponent extends DestroyedSubjectContainerMixin() implements OnInit {

  faIcons = { faListAlt, faUser, faCreditCard, faSmile, faCheckCircle, faEdit };

  itemProLifetime = shared.purchaseItems[shared.purchaseItemProLifetimeV1];
  itemProYearly = shared.purchaseItems[shared.purchaseItemProYearlyV2];
  itemProMonthly = shared.purchaseItems[shared.purchaseItemProMonthlyV2];

  user: User;
  item: PurchaseItem;
  sourceId: string;
  coupon: string;

  hideStepperHeader = true;
  @ViewChild(MatHorizontalStepper, { static: true }) stepper: MatHorizontalStepper;

  @ViewChild(PaymentFormComponent, { static: false }) pmtForm: PaymentFormComponent;

  constructor(
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    public auth: AuthService,
    public pmt: PaymentService,
    public snackBar: MatSnackBar,
    private angulartics2: Angulartics2,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager) {
    super();

    this.item = this.itemProYearly;
  }

  ngOnInit() {
  }

  createPurchase(item: PurchaseItem) {
    this.hideStepperHeader = false;

    this.item = item;

    this.changeDetector.detectChanges();

    this.stepper.selected.completed = true;
    this.stepper.next();
  }

  continueOnUserAuth(user: User) {
    if (!user) {
      return;
    }

    this.user = user;

    this.changeDetector.detectChanges();

    this.stepper.selected.completed = true;
    this.stepper.next();
  }

  purchaseSuccess(itemId: string) {
    this.stepper.selected.completed = true;
    this.stepper.next();

    this.angulartics2.eventTrack.next({
      action: 'Purchase',
      properties: {
        category: 'Purchase',
        label: itemId
      },
    });

    if (this.user && this.user.email) {
      this.angulartics2GoogleTagManager.setUsername(this.user.email);
    }
    this.angulartics2GoogleTagManager.eventTrack('conversion', {
      'send_to': 'AW-749399906/LfMACOz6_NoBEOLeq-UC'
    });

    setTimeout(() => {
      this.router.navigate(['user', 'settings']).then(() => {
        window.location.reload();
      });
    }, 9000);
  }

  cancelPurchase(itemId: string) {
    this.router.navigate(['user', 'settings']).then(() => {
      window.location.reload();
    });
  }

  startPurchaseNoUser(item: PurchaseItem) {
    this.hideStepperHeader = false;

    this.item = item;

    this.changeDetector.detectChanges();

    this.stepper.selected.completed = true;
    this.stepper.next();
  }

  showMessage(message: string, duration: number = 5 * 60 * 1000) {
    return this.snackBar
      .open(message, 'Dismiss', {
        duration
      })
      .afterDismissed();
  }

}
