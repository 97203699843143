import { ValidatorFn, AbstractControl, FormGroup } from "@angular/forms";

export abstract class AbstractControlEx extends AbstractControl {
  errorMessage: string;
}

export class FormGroupEx extends FormGroup {
  controls: {
    [key: string]: AbstractControlEx;
  };
};

export function validateWithErrorMessages(validators: ValidatorFn[], errorMessages: { [key: string]: string }): ValidatorFn {
  return (control: AbstractControlEx): { [key: string]: any } | null => {
    const results = validators
      .map(x => x(control));

    const allValidationErrors = results
      .reduce((prev, cur) => { return { ...prev, ...cur }; }, {});

    if (control.dirty) {

      const trueErrors = Object
        .keys(allValidationErrors)
        .filter(x => allValidationErrors[x] && errorMessages.hasOwnProperty(x));

      if (trueErrors && trueErrors.length) {
        trueErrors.some(x => {
          if (errorMessages.hasOwnProperty(x)) {
            control.errorMessage = errorMessages[x];
            return true;
          }
          return false;
        })
      } else {
        control.errorMessage = null;
      }

    }

    for (const key in allValidationErrors) {
      return allValidationErrors;
    }

    return null;
  }
};

export function validateIf(validator: ValidatorFn, ifCheck: Function): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return ifCheck(control) ? validator(control) : null;
  }
};
