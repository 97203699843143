import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { NgxStripeModule } from 'ngx-stripe';

import { SharedModule } from '../shared/shared.module';

import { StripePipe } from './stripe.pipe';
import { KeysPipe } from './keys.pipe';

import { environment } from '../../environments/environment';

import { PaymentFormComponent } from './payment-form/component';
import { PaymentItemActionsComponent } from './payment-item-actions/component';
import { PaymentUpgradeComponent } from './payment-upgrade/component';
import { PaymentPurchasesComponent } from './payment-purchases/component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatStepperModule,

    FontAwesomeModule,

    NgxStripeModule.forRoot(environment.stripePublishable),

    SharedModule,
  ],
  declarations: [
    KeysPipe,
    StripePipe,
    PaymentFormComponent,
    PaymentItemActionsComponent,
    PaymentPurchasesComponent,
    PaymentUpgradeComponent,
  ],
  exports: [
    KeysPipe,
    StripePipe,
    PaymentFormComponent,
    PaymentItemActionsComponent,
    PaymentPurchasesComponent,
    PaymentUpgradeComponent,
  ],
  providers: []
})

export class PaymentModule { }
