// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,

  domain: 'localhost',
  contact: 'contact@adinterestpro.com',
  title: 'AdInterestPro: Facebook Marketing Tool',

  firebase: {
    apiKey: 'AIzaSyCpk_aj4-QJHjWUxA_A7_b3OSOZ1Fx_8No',
    authDomain: 'adinterestpro-dev.firebaseapp.com',
    databaseURL: 'https://adinterestpro-dev.firebaseio.com',
    projectId: 'adinterestpro-dev',
    storageBucket: 'adinterestpro-dev.appspot.com',
    messagingSenderId: '124017422523',
    appId: '1:124017422523:web:ad64fba827fef75584f99e'
  },

  functionsURL: 'https://us-central1-adinterestpro-dev.cloudfunctions.net',

  stripePublishable: 'pk_test_yO8qzRKUqTDqye9E71VPIRbf',

  stripe_planId_pro_mo: 'plan_Eb1iM9IX7BJjmu',
  stripe_planId_pro_yr: 'plan_Eb1jS1ZUAAML6U',
  stripe_itemId_pro_lifetime: 'item_pro_lifetime',

  tawk: '5dab61f578ab74187a5a8005'
};
