import {
  Component,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { first } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material/snack-bar';
import { Angulartics2 } from 'angulartics2';
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from 'ngx-stripe';

import { AuthService } from '../../shared/services/auth.service';
import { PaymentService } from '../../shared/services/payment.service';
import { ExService } from '../../shared/services/ex.service';

import { PurchaseData, ApiResponse, PurchasedItem, PurchasedItems } from '../../../shared';
import * as shared from '../../../shared';

@Component({
  selector: 'app-payment-form',
  templateUrl: './component.html',
  styleUrls: ['./component.sass']
})
export class PaymentFormComponent implements AfterViewInit, OnDestroy {

  @Input() itemId: string;
  @Input() onPurchaseSuccess: Function;

  // Result used locacally to display status.
  result: any;
  sourceId: string;

  // The Stripe Elements Card
  @ViewChild('cardElement', { static: true }) cardElement: ElementRef;
  card: StripeElement;
  formError: string;
  formComplete = false;

  // Coupon
  coupon: string;

  // State of async activity
  loading = false;

  constructor(
    private cd: ChangeDetectorRef,
    public auth: AuthService,
    public pmt: PaymentService,
    private stripe: StripeService,
    private exService: ExService,
    public snackBar: MatSnackBar,
    private angulartics2: Angulartics2
  ) { }

  ngAfterViewInit() {
    this.stripe.elements().pipe(first()).subscribe(elements => {
      this.card = elements.create('card', {});
      this.card.mount(this.cardElement.nativeElement);

      // Listens to change event on the card for validation errors
      this.card.on('change', (evt) => {
        this.formError = evt.error ? evt.error.message : null;
        this.formComplete = evt.complete;
        this.cd.detectChanges();
      });
    });
  }

  ngOnDestroy() {
    if (this.card) {
    this.card.unmount();
    }
  }

  // Called when the user submits the form
  formHandler(): void {
    this.loading = true;

    this.pmt.createStripeSourceFromCard(this.card).subscribe(
      data => {
        this.result = data.source;
        this.sourceId = data.source.id;

        this.createPurchaseFromStripeSource();

        this.loading = false;
      },
      err => {
        this.showMessage(
          'There was some error processing given information.');

        this.result = err;
        this.sourceId = null;

        this.loading = false;
      }
    );
  }

  createPurchaseFromStripeSource() {
    this.loading = true;

    const purchaseData: PurchaseData = {
      ...shared.purchaseItems[this.itemId],
      ...{ source: this.sourceId, coupon: this.coupon }
    };

    this.pmt.createPurchase(purchaseData).pipe(first()).subscribe(res => {
      this.result = res;

      if (res.data && res.data.status === 'active') {
        this.snackBar.open(
          'You purchase was successfull! You will be charged from now on.',
          '',
          {
            duration: 7000
          });

        this.angulartics2.eventTrack.next({
          action: 'Checkout - Completed',
          properties: {
            category: 'Checkout',
            label: this.itemId,
          },
        });

        if (this.onPurchaseSuccess) {
          this.onPurchaseSuccess(this.itemId);
        }
      } else {
        let msg = '';
        if (res.data && res.data.gatewayData.message) {
          msg = res.data.gatewayData.message;
        }
        this.snackBar.open(
          msg + ' There was some error processing your purchase. If this happens multiple times, please contact us.',
          '',
          {
            duration: 21000
          });

        this.angulartics2.eventTrack.next({
          action: 'Checkout - Failed',
          properties: {
            category: 'Checkout',
            label: this.itemId,
          },
        });

      }

      this.loading = false;
    }, err => {
      this.result = err;

      this.angulartics2.eventTrack.next({
        action: 'Checkout - Failed',
        properties: {
          category: 'Checkout',
          label: this.itemId,
        },
      });

      this.snackBar.open(
        'There was some error while processing your purchase. If this happens multiple time, please contact us.',
        '',
        {
          duration: 7000
        });

      this.loading = false;
    });
  }

  showMessage(message: string, duration: number = 5 * 60 * 1000) {
    return this.snackBar
      .open(message, 'Dismiss', {
        duration
      })
      .afterDismissed();
  }

}
