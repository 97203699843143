
export interface PurchaseItem {
  id: string;
  name: string;
  type: 'onetime' | 'subscription';
  amountOriginal: number;
  amount: number;
  currency: string;
  currencySymbol: string;
  discountText: string | null;
  recurrenceType: 'mo' | 'yr' | null;
}

export interface PurchaseItems { [key: string]: PurchaseItem; }

export interface PurchaseData extends PurchaseItem {
  source: string;
  coupon: string;
}

export interface PurchasedItem {
  purchaseData: PurchaseData;
  gatewayData: any;
  status: 'success' | 'failure' | 'active' | 'cancelled' | 'none';
  feedback?: string;
  timestamp: string;
  updateTimestamp: string;
}

export interface PurchasedItems { [key: string]: PurchasedItem; }

export const purchaseItemProLifetimeV1 = "item_pro_lifetime_v1";
export const purchaseItemProYearlyV2 = "item_pro_yr_v2";
export const purchaseItemProMonthlyV2 = "item_pro_mo_v2";

// NOTE: Discontinued 2020-03
export const purchaseItemProYearlyV1 = "item_pro_yr_v1";
export const purchaseItemProMonthlyV1 = "item_pro_mo_v1";

// Stripe mappings
export const stripeLivePlanIdsForPurchaseItems = {
  [purchaseItemProYearlyV2]: "plan_GqjGm5olm4PzH8",
  [purchaseItemProMonthlyV2]: "plan_GqjHoMcXUSf5CL",

  [purchaseItemProYearlyV1]: "plan_EWWUZ3yv6blwyn",
  [purchaseItemProMonthlyV1]: "plan_Eb1k4EokCeyCtp",
};

export const stripeTestPlanIdsForPurchaseItems = {
  [purchaseItemProYearlyV2]: "plan_GqjJcUZ0gP0tFR",
  [purchaseItemProMonthlyV2]: "plan_GqjJ6Wui8Fi3bL",

  [purchaseItemProYearlyV1]: "plan_Eb1jS1ZUAAML6U",
  [purchaseItemProMonthlyV1]: "plan_Eb1iM9IX7BJjmu",
};

// Migration mappings
export const purchaseItemMigrations = {
  [purchaseItemProYearlyV1]: purchaseItemProYearlyV2,
  [purchaseItemProMonthlyV1]: purchaseItemProMonthlyV2,
};

export const purchaseItems: PurchaseItems = {
  [purchaseItemProLifetimeV1]: {
    id: purchaseItemProLifetimeV1,
    name: "PRO Lifetime",
    type: "onetime",
    recurrenceType: null,
    amountOriginal: 30000,
    amount: 30000,
    currency: "usd",
    currencySymbol: "$",
    discountText: null,
  },

  [purchaseItemProMonthlyV2]: {
    id: purchaseItemProMonthlyV2,
    name: "PRO Monthly",
    type: "subscription",
    recurrenceType: 'mo',
    amountOriginal: 4000,
    amount: 1999,
    currency: "usd",
    currencySymbol: "$",
    discountText: "Save 50%",
  },

  [purchaseItemProYearlyV2]: {
    id: purchaseItemProYearlyV2,
    name: "PRO Yearly",
    type: "subscription",
    recurrenceType: 'yr',
    amountOriginal: 25000,
    amount: 17500,
    currency: "usd",
    currencySymbol: "$",
    discountText: "Save 30%",
  },

  [purchaseItemProYearlyV1]: {
    id: purchaseItemProYearlyV1,
    name: "PRO Yearly",
    type: "subscription",
    recurrenceType: 'yr',
    amountOriginal: 8400,
    amount: 8400,
    currency: "usd",
    currencySymbol: "$",
    discountText: null,
  },

  [purchaseItemProMonthlyV1]: {
    id: purchaseItemProMonthlyV1,
    name: "PRO Monthly",
    type: "subscription",
    recurrenceType: 'mo',
    amountOriginal: 900,
    amount: 900,
    currency: "usd",
    currencySymbol: "$",
    discountText: null,
  },
};
