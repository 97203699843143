import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-mat-styles',
  template: '',
  styleUrls: ['../../../../assets/scss/mat.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MatStylesComponent {

  constructor() {
  }

}
