import { Renderer2, Inject, Injectable, Optional } from '@angular/core';
import { WINDOW, LOCAL_STORAGE } from '@ng-toolkit/universal';
import { DOCUMENT } from '@angular/common';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ExService {

  constructor(
    @Optional() @Inject(WINDOW) public window: Window,
    @Optional() @Inject(LOCAL_STORAGE) public localStorage: any,
    @Optional() @Inject(DOCUMENT) private document: Document) {
  }

  public injectScript(renderer2: Renderer2, text: string): void {
    const script = renderer2.createElement('script');
    script.type = 'text/javascript';
    script.text = `${text}`;
    renderer2.appendChild(this.document.body, script);
  }

  injectTwak() {
    const hash = environment.tawk;
    if (!hash) {
      return;
    }

    const s1 = this.document.createElement('script'), s0 = this.document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = `https://embed.tawk.to/${hash}/default`;
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  }

  injectHeatmap() {
    (function(h, e, a, t, m, p) {
      m = e.createElement(a); m.async = !0; m.src = t;
      p = e.getElementsByTagName(a)[0]; p.parentNode.insertBefore(m, p);
    })(this.window, this.document, 'script', 'https://u.heatmap.it/log.js');
  }

  getRandomGreeting() {
    const greetings = [
      'Hello',
      'Welcome back',
      'Hey',
      'Welcome',
      'Hi',
      'G’day',
    ];
    const greetingsIndex = Math.floor(Math.random() * greetings.length);
    return greetings[greetingsIndex];
  }

  getFromLocalStorage<T>(key: string, def: T) {
    const value = this.localStorage.getItem(key);
    if (!value) {
      return def;
    }
    const parsed = JSON.parse(value);
    if (parsed === null) {
      return def;
    }
    return parsed;
  }

  setToLocalStorage<T>(key: string, value: T) {
    this.localStorage.setItem(key, JSON.stringify(value));
  }

}
