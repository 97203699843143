import { NgModule, APP_INITIALIZER, PLATFORM_ID, Injector } from '@angular/core';
import { CommonModule, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgtUniversalModule } from '@ng-toolkit/universal';

import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { NgProgressRouterModule } from '@ngx-progressbar/router';

import 'firebase/auth';
import 'firebase/firestore';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { Angulartics2Module } from 'angulartics2';

import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';

import { AnimateOnScrollModule } from './utils/animate-on-scroll';

import { AuthService } from './shared/services/auth.service';
import { AuthGuard } from './shared/guards/auth.guard';
import { AdminGuard } from './shared/guards/admin.guard';
import { ItemProGuard } from './shared/guards/itempro.guard';

import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { environment } from './../environments/environment';

import { AppComponent } from './app.component';

import { PaymentModule } from './payment/payment.module';

import { _404Component } from './404/404.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },

  { path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },

  { path: '404', component: _404Component },
  { path: '**', redirectTo: '404' }
];

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.domain
  },
  position: 'bottom',
  theme: 'edgeless',
  palette: {
    popup: {
      background: '#000000',
      text: '#ffffff',
      link: '#ffffff'
    },
    button: {
      background: '#4caf50',
      text: '#000000',
      border: 'transparent'
    }
  },
  type: 'info',
  content: {
    message: 'We use cookies to ensure you get the best experience on our website and webapp.',
    dismiss: 'Got it!',
    deny: 'Refuse cookies',
    link: 'Learn more',
    href: '/privacy',
    policy: 'Cookie Policy'
  }
};

@NgModule({
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'app' }),
    RouterModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 12],
      initialNavigation: 'legacy_enabled'
    }),
    HttpClientModule,
    BrowserAnimationsModule,

    NgtUniversalModule,

    AnimateOnScrollModule.forRoot(),

    NgProgressModule.withConfig({
      color: '#0d47a1',
      thick: true,
      meteor: true
    }),
    NgProgressHttpModule,
    NgProgressRouterModule,

    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,

    PaymentModule,

    Angulartics2Module.forRoot(),

    NgcCookieConsentModule.forRoot(cookieConfig),
  ],
  declarations: [
    AppComponent,
    _404Component,
  ],
  providers: [
    AuthService,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    AdminGuard,
    ItemProGuard,

    // Disable console logging in prod
    {
      provide: APP_INITIALIZER,
      useFactory: (injector: Injector) => () => {
        if (!environment.production) {
          return;
        }

        const platformId = injector.get(PLATFORM_ID);

        if (isPlatformBrowser(platformId)) {
          window.console.log = window.console.debug = window.console.warn = () => { };
        } else if (isPlatformServer(platformId)) {
          console.log = console.debug = console.warn = () => { };
        }
      },
      deps: [Injector],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
