import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Cacheable } from 'ngx-cacheable';
import { WINDOW } from '@ng-toolkit/universal';

import { environment } from '../../../environments/environment';
import { ApiResponse } from 'shared';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  readonly api = `${environment.functionsURL}/app`;
  readonly publicApi = `${environment.functionsURL}/publicApi`;

  constructor(@Optional() @Inject(WINDOW) public window: Window, private http: HttpClient) { }

  @Cacheable()
  getAds(terms: string, limit?: string) {
    return this.http.get<ApiResponse<any, any>>(
      `${this.api}/ads`,
      { params: { terms, limit } }
    );
  }

  @Cacheable()
  getKeywordQuerySuggestions(query: string, limit?: string) {
    return this.http.get<ApiResponse<any, any>>(
      `${this.api}/keyword_query_suggestions`,
      { params: { query, limit } }
    );
  }

  @Cacheable()
  getKeywords(query: string, limit?: string, locale?: string) {
    return this.http.get<ApiResponse<any, any>>(
      `${this.api}/keywords`,
      { params: { query, limit, locale } }
    );
  }

  @Cacheable()
  find(query: string, count: number) {
    return this.http.post<any>(
      `${this.api}/find/`,
      { query, count }
    );
  }

  @Cacheable()
  findAndSearch(query: string, count: number, limit: number, locale: string) {
    return this.http.post<any>(
      `${this.api}/findandsearch/`,
      { query, count, limit, locale }
    );
  }

  @Cacheable()
  getEventsCount() {
    return this.http.get<any>(
      `${this.api}/events/count`
    );
  }

  @Cacheable()
  sendEvent(cat: string, act: string, lbl: string, val: any) {
    return this.http.post<any>(
      `${this.api}/event/`,
      { cat, act, lbl, val }
    );
  }

  @Cacheable()
  getEvents(cat: string, act: string) {
    return this.http.post<any>(
      `${this.api}/events/`,
      { cat, act }
    );
  }

  @Cacheable()
  getEventsForUser(uid: string, cat: string, act: string) {
    return this.http.post<any>(
      `${this.api}/events/`,
      { uid, cat, act }
    );
  }

  @Cacheable()
  getUsersCount() {
    return this.http.get<any>(
      `${this.api}/users/count`
    );
  }

  @Cacheable()
  getUserDataUID(uid: string) {
    return this.http.post<any>(
      `${this.api}/user/data/uid`,
      { uid }
    );
  }

  @Cacheable()
  getUserDataSubscriptions(uid: string) {
    return this.http.post<any>(
      `${this.api}/user/data/subscriptions`,
      { uid }
    );
  }

  getUserSubscriptions(uid: string) {
    return this.http.post<any>(
      `${this.api}/user/subscriptions`,
      { uid }
    );
  }

  @Cacheable()
  invite(email: string) {
    return this.http.post<any>(
      `${this.publicApi}/emails/invite`,
      { email }
    );
  }

  copyToClipboard($event, content, callback?) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (content));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    if (callback) {
      callback();
    }
  }

  downloadToText($event, content, filename, callback?) {
    this.saveTextAsFile(content, filename + '.txt');
    if (callback) {
      callback();
    }
  }

  downloadToCSV($event, content, filename, callback?) {
    this.saveTextAsFile(content, filename + '.csv');
    if (callback) {
      callback();
    }
  }

  saveTextAsFile(data, filename) {
    if (!data) {
      console.error('Console.save: No data');
      return;
    }

    if (!filename) { filename = 'data.txt'; }

    const blob = new Blob([data], { type: 'text/plain' });

    if (this.window.navigator && this.window.navigator.msSaveOrOpenBlob) {
      this.window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');

      a.download = filename;
      a.href = (this.window as any).URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/plain', a.download, a.href].join(':');
      e.initEvent('click', true, false);
      a.dispatchEvent(e);
    }
  }

}
