import { Component, Input } from '@angular/core';

import { PurchaseItem } from '../../../../shared';

@Component({
  selector: 'app-price-discount',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class PriceDiscountComponent {

  @Input() purchaseItem: PurchaseItem;

  constructor() {
  }

}
