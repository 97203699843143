import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { PaymentService } from '../services/payment.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ItemProGuard implements CanActivate {

  constructor(private auth: AuthService, private pmt: PaymentService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.pmt.isPurchasedItemActive().pipe(
      tap(has => {
        if (!has) {
          if (!(next.url.join('/').indexOf('pricing') > -1)) {
            this.router.navigate(['/pricing']);
          }
        }
      })
    );

  }
}
