import { Component, OnInit, TemplateRef } from '@angular/core';
import { Cacheable, globalCacheBusterNotifier } from 'ngx-cacheable';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { first } from 'rxjs/operators';

import { Angulartics2 } from 'angulartics2';

import { faMoneyBill, faTachometerAlt, faWindowClose, faBoxes, faMinus, faQuestion, faEllipsisH } from '@fortawesome/free-solid-svg-icons';

import { PaymentService } from '../../shared/services/payment.service';
import { AppService } from '../../shared/services/app.service';

import { PurchaseData, ApiResponse, PurchasedItem, PurchasedItems, PurchaseItem } from '../../../shared';
import * as shared from '../../../shared';

@Component({
  selector: 'app-payment-purchases',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class PaymentPurchasesComponent implements OnInit {

  faIcons = { faMoneyBill, faTachometerAlt, faWindowClose, faBoxes, faMinus, faQuestion, faEllipsisH };

  purchases: PurchasedItem[]
  cancelDialogRef: MatDialogRef<any>;
  cancelItemId: any;
  cancelFeedbackItems = [
    {
      icon: faMoneyBill,
      class: 'text-success',
      text: 'Cost',
      selected: false
    },
    {
      icon: faTachometerAlt,
      class: 'text-danger',
      text: 'Difficulty',
      selected: false
    },
    {
      icon: faWindowClose,
      class: 'text-danger',
      text: 'Missing functionality',
      selected: false
    },
    {
      icon: faBoxes,
      class: 'text-warning',
      text: 'Using other products',
      selected: false
    },
    {
      icon: faMinus,
      class: 'text-danger',
      text: 'Not using it',
      selected: false
    },
    {
      icon: faQuestion,
      class: 'text-success',
      text: 'Lack of support',
      selected: false
    }
  ];
  cancelFeedbackSomethingElse: string;

  constructor(
    private pmt: PaymentService,
    private appService: AppService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private angulartics2: Angulartics2
  ) { }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.pmt.getPurchases().pipe(first()).subscribe(res => {
      if (res.error) {
        return;
      }

      this.purchases = Object.values(res.data).map(value => {
        if (value.purchaseData && value.purchaseData.id) {
          value.purchaseData = { ...shared.purchaseItems[value.purchaseData.id], ...value.purchaseData };
        }
        return value;
      });
    });
  }

  cancelPurchased() {
    const feedbacks = [];

    const cancelFeedbacks = this.cancelFeedbackItems.filter(x => x.selected).map(x => x.text).join(', ');
    if (cancelFeedbacks && cancelFeedbacks.length > 0) {
      feedbacks.push(`Selected: ${cancelFeedbacks}`);
    }

    if (this.cancelFeedbackSomethingElse && this.cancelFeedbackSomethingElse.length > 0) {
      feedbacks.push(`Entered: ${this.cancelFeedbackSomethingElse}`);
    }

    const feedback = feedbacks.join(' \n');

    this.pmt.cancelPurchased(this.cancelItemId, feedback).subscribe(data => {
      this.angulartics2.eventTrack.next({
        action: 'Purchase - Canceled',
        properties: {
          category: 'Purchase',
          label: this.cancelItemId
        }
      });

      this.snackBar.open(
        'You purchase was cancelled successfully! You will not be charged any more.',
        '',
        {
          duration: 7000
        }
      );

      globalCacheBusterNotifier.next();
      this.refresh();
    });

    if (this.cancelDialogRef) {
      this.cancelDialogRef.close();
    }
  }

  openCancelDialog(itemId: any, templateRef: TemplateRef<any>) {
    this.cancelItemId = itemId;

    this.cancelDialogRef = this.dialog.open(templateRef, {
      width: '600px'
    });
  }
}
