import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { takeUntil, first } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Angulartics2 } from 'angulartics2';

import { DestroyedSubjectContainerMixin } from '../../../utils/angular-rxjs/destroyed-subject-container.mixin';

import { environment } from '../../../../environments/environment';
import { User } from '../../models/user';

import { AuthService } from '../../services/auth.service';
import { ExService } from '../../services/ex.service';

import { validateIf, validateWithErrorMessages, FormGroupEx } from '../../../utils/form.utils';

@Component({
  selector: 'app-user-auth',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class UserAuthComponent extends DestroyedSubjectContainerMixin() implements OnInit {

  @Input() onContinue: Function;

  userForm: FormGroupEx;

  newUser = true;
  user: User;
  passwordVisible = false;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    public snackBar: MatSnackBar,
    private angulartics2: Angulartics2,
    public exService: ExService
  ) {
    super();
  }

  buildForm(): void {
    this.userForm = new FormGroupEx({
      name: new FormControl('',
        validateWithErrorMessages([
          validateIf(Validators.required, () => this.newUser)
        ], {
          required: 'Required.'
        })
      ),

      email: new FormControl('',
        validateWithErrorMessages([
          Validators.required,
          Validators.email
        ], {
          required: 'Required.',
          email: 'Must be a valid email.'
        })
      ),

      password: new FormControl('',
        validateWithErrorMessages([
          validateIf(Validators.required, () => this.newUser),
          Validators.pattern(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/),
          Validators.minLength(6)
        ], {
          required: 'Required.',
          pattern: 'Must include at least one small letter, one capital letter, one number and one special letter.',
          minlength: 'Must be at least 6 characters long.'
        })
      ),

      mailinglist: new FormControl(false),

      agree: new FormControl(false,
        validateWithErrorMessages([
          Validators.requiredTrue
        ], {
          required: 'You need to accept the Terms of Service and Privacy Policy.'
        })
      )
    });
  }

  ngOnInit(): void {
    this.buildForm();

    this.auth.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
      this.user = user;

      if (this.userForm && this.user && this.user.email) {
        this.newUser = false;
        this.userForm.controls['name'].setValue(this.user.displayName);
        this.userForm.controls['email'].setValue(this.user.email);
        this.userForm.controls['password'].setValue('');
      }
    });
  }

  processGoogle() {
    this.auth.googleLogin(this.userForm.value.name, this.userForm.value.mailinglist)
      .then(() => {
        this.angulartics2.eventTrack.next({
          action: 'Login',
          properties: {
            category: 'Auth',
            label: 'Google',
          },
        });

        this.continue();
      })
      .catch(error => {
        if (error.code === 'auth/account-exists-with-different-credential') {
          this.showMessage(`Please try with email and password.`);
        }
        this.showMessage(`We're not able to sign you in. Please contact us at ${environment.contact}.`);
      });
  }

  processForm(): void {
    this.auth
      .emailSignup(
        this.userForm.value.name,
        this.userForm.value.email,
        this.userForm.value.password,
        this.userForm.value.mailinglist)
      .then(() => {
        this.angulartics2.eventTrack.next({
          action: 'Signup',
          properties: {
            category: 'Auth',
            label: 'Email',
          },
        });

        this.continue();
      })
      .catch(reason => {
        this.showMessage(
          'Well, we\'re not able to register you.'
          + ' Please check for correct email and password.'
          + ' Maybe you already have an account, try logging in or use forget password to reset.');
      });
  }

  continue(): void {
    if (this.onContinue) {
      this.onContinue(this.user);
    }
  }

  showMessage(message: string, duration: number = 5 * 60 * 1000) {
    return this.snackBar
      .open(message, 'Dismiss', {
        duration
      })
      .afterDismissed();
  }

}
