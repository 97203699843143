import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TransferHttpModule } from '@gorniv/ngx-universal';

import { PaymentService } from './services/payment.service';

import { MatStylesComponent } from '../shared/components/mat-styles/component';

import { PriceDiscountComponent } from '../shared/components/price-discount/component';
import { UserAuthComponent } from '../shared/components/user-auth/component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatCheckboxModule,
  ],
  exports: [
    TransferHttpModule,

    MatStylesComponent,

    PriceDiscountComponent,
    UserAuthComponent,
  ],
  declarations: [
    MatStylesComponent,

    PriceDiscountComponent,
    UserAuthComponent,
  ],
  providers: [
    PaymentService
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return { ngModule: SharedModule };
  }
}
