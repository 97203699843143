
import { filter, map, mergeMap } from 'rxjs/operators';

import { Component } from '@angular/core';
import { Router, NavigationEnd, RouterState, ActivatedRoute, ActivationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2Facebook } from 'angulartics2/facebook';
import { Angulartics2Woopra } from 'angulartics2/woopra';

import { environment } from '../environments/environment';

import { getRouteTitle } from './utils/routeutils';

declare var materialKit: any;

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet><ng-progress></ng-progress>',
})
export class AppComponent {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private angulartics2Facebook: Angulartics2Facebook,
    private angulartics2Woopra: Angulartics2Woopra
  ) {
    // Init trackers
    this.angulartics2GoogleAnalytics.startTracking();
    this.angulartics2Facebook.startTracking();
    this.angulartics2Woopra.startTracking();

    // Route changes
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(null, null, () => {
        try {
          materialKit.fixNavbar();
        } catch (e) {
          // ?
        }

        this.angulartics2GoogleAnalytics.startTracking();
        this.angulartics2Facebook.startTracking();
        this.angulartics2Woopra.startTracking();
      });

    // Titles
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => getRouteTitle(this.activatedRoute))
      )
      .subscribe((title) => this.titleService.setTitle((!!title) ? `${title} | ${environment.title}` : environment.title));
  }
}
